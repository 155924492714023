import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { Toast, Dialog } from 'vant'
import { Coupon, LandingBanner } from '../types'
import { useSms } from '@/hooks/useSms'
import { mobileRegExp } from '@/utils/regExp'
import { setAuth } from '@/utils/adapter'
import { getCouponLandingInfo, receiveCoupon } from '@/api/couponLanding'
import { sendSmsCode } from '@/api/member'
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/types'
import { useStore } from '@/store'
import { ResponseCode } from '@/constants/http'

export default function () {
  const { query } = useRoute()
  const code = query.code as string
  const store = useStore()

  // 获取验证码前的表单验证
  const validateSms = () => {
    if (!phone.value) {
      Toast('请先填写手机号')
      return false
    }
    if (!mobileRegExp.test(phone.value)) {
      Toast('手机号格式不正确')
      return false
    }
    return true
  }

  // 获取验证码发送请求
  const requestSms = () => {
    console.log('发送验证码')
    sendSmsCode(phone.value)
      .then(() => {
        Toast('验证码发送成功')
      })
      .catch(() => {})
  }

  // 获取验证码的核心逻辑
  const { smsDisabled, smsBtnText, seconds, onClickSmsBtn, setTimer } = useSms({
    validate: validateSms,
    request: requestSms,
  })

  // 是否活动不存在
  const notExist = ref(false)
  const id = ref('')
  // 活动是否过期
  const overdue = ref(false)
  const bannerUrl = ref('')
  const landingBanner = ref<LandingBanner[]>([])
  const couponList = ref<Coupon[]>([])
  // 优惠券落地页名称
  const landingName = ref('')
  const startTime = ref('')
  const endTime = ref('')
  const backgroundColor = ref('')
  const buttonBackgroundColor = ref('')
  const buttonLabel = ref('')
  const phone = ref('')
  const sms = ref('')

  const couponsLink = computed(() => {
    const { origin, host } = location
    if (host === 'mall.iflysec.com') {
      // 测试环境
      return `${origin}/ifly-mall/ecshop/h5/#/couponsList`
    } else {
      // 正式环境
      return `${origin}/h5/#/couponsList`
    }
  })

  // 电话为空 || 短信验证码为空 | 活动已过期
  const submitDisabled = computed(() => phone.value === '' || sms.value === '' || overdue.value)

  const submitText = computed(() =>
    overdue.value ? '活动已结束' : buttonLabel.value || '立即领取'
  )

  const fetchInfo = () => {
    getCouponLandingInfo(code)
      .then((res) => {
        id.value = res.data.data.id
        overdue.value = res.data.data.overdue
        bannerUrl.value = res.data.data.bannerUrl
        landingBanner.value = res.data.data.landingBanner
        couponList.value = res.data.data.couponList
        landingName.value = res.data.data.landingName
        startTime.value = res.data.data.startTime
        endTime.value = res.data.data.endTime
        backgroundColor.value = res.data.data.backgroundColor
        buttonBackgroundColor.value = res.data.data.buttonBackgroundColor
        buttonLabel.value = res.data.data.buttonLabel

        document.title = `${landingName.value}-讯飞商城`
      })
      .catch((error) => {
        console.log('error', error)
        if (error?.data?.code === ResponseCode.ActivityNotExist) {
          // 活动不存在
          notExist.value = true
        }
      })
  }

  // 弹窗
  const openModal = (
    title: string,
    message: string,
    confirmButtonText: string
  ): Promise<boolean> => {
    return Dialog.alert({
      title,
      message,
      confirmButtonText,
      theme: 'round-button',
      confirmButtonColor: '#427eff',
      closeOnClickOverlay: true,
    }).then(() => {
      console.log('确定')
      return Promise.resolve(true)
    })
  }

  // 提交
  const submit = () => {
    if (!phone.value) {
      Toast('请输入手机号')
      return false
    }
    if (!mobileRegExp.test(phone.value)) {
      Toast('手机号格式不正确')
      return false
    }
    if (!sms.value) {
      Toast('请输入验证码')
      return false
    }
    login()
  }

  // 登录
  const login = async () => {
    const data = await store.dispatch(AuthActionTypes.LOGIN, {
      loginType: 1, // 1短信验证码 2账号密码
      mobile: phone.value,
      verificationCode: sms.value,
      businessId: id.value, // 业务主键标识
      regCause: 'coupon_landing_register_cause', // 注册原因：优惠券落地页用户注册统计
    })
    console.log('data', data, store)
    // 为了与老项目通信，只能通过localStorage来通信，通过设置localStorage间接设置store
    setAuth(Object.assign({}, data, { isOnline: true }))
    fetchReceiveCoupon()
  }

  // 领取优惠券
  const fetchReceiveCoupon = () => {
    enum Code {
      success = 0, // 领取成功
      nomore = -1, // 已领完
      hasReceived = -2, // 已领取，勿重复领取
      expired = -99, // 活动已过期
    }
    receiveCoupon(code)
      .then((res) => {
        const code = res.data.data.code as Code
        let title: string
        let message = '可在 讯飞商城-我的券码 查看使用'
        let confirmButtonText = '立即查看'
        switch (code) {
          case Code.success:
            title = '恭喜你，领取成功'
            break
          case Code.nomore:
            title = '领取失败，优惠券已被领完'
            break
          case Code.hasReceived:
            title = '已领取，请勿重复领取'
            break
          case Code.expired:
            title = '活动已过期'
            message = '敬请关注科大讯飞其他活动'
            confirmButtonText = '好的'
        }
        openModal(title, message, confirmButtonText).then(() => {
          if (code !== Code.expired) {
            location.href = couponsLink.value
          }
        })
      })
      .catch(() => {})
  }

  onMounted(() => {
    fetchInfo()
  })

  return {
    validateSms,
    requestSms,
    smsDisabled,
    smsBtnText,
    seconds,
    onClickSmsBtn,
    setTimer,

    notExist,
    id,
    overdue,
    bannerUrl,
    landingBanner,
    couponList,
    landingName,
    startTime,
    endTime,
    backgroundColor,
    buttonBackgroundColor,
    buttonLabel,
    phone,
    sms,
    couponsLink,
    submitDisabled,
    submitText,
    fetchInfo,
    submit,
  }
}
