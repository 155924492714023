import request from '@/request'
import { Params } from './types'

/**
 * 优惠券落地页信息
 * @param landingCode
 * @param applyType 0:pc, 1:H5, 2:小程序
 */
export const getCouponLandingInfo = (landingCode: string) =>
  request.get(`/coupon/coupon/landing/info/${landingCode}/1`)

// 领取优惠券
export const receiveCoupon = (landingCode: string) =>
  request.get(`/coupon/coupon/receive/${landingCode}`)

// 优惠品落地页信息
export const getDiscountLandingInfo = (landingCode: string) =>
  request.get(`/coupon/landing/goods/info/${landingCode}/1`)

// 优惠品落地页购买
export const getDiscountLandingFcode = (params?: Params) =>
  request.post(`/coupon/landing/goods/buy/now`, params)
