
import { defineComponent } from 'vue'
import { Button } from 'vant'
import useInfo from './model/useInfo'

export default defineComponent({
  name: 'CouponLanding',

  components: {
    [Button.name]: Button,
  },

  setup() {
    const {
      validateSms,
      requestSms,
      smsDisabled,
      smsBtnText,
      seconds,
      onClickSmsBtn,
      setTimer,

      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      couponList,
      landingName,
      startTime,
      endTime,
      backgroundColor,
      buttonBackgroundColor,
      buttonLabel,
      phone,
      sms,
      couponsLink,
      submitDisabled,
      submitText,
      fetchInfo,
      submit,
    } = useInfo()

    return {
      smsDisabled,
      smsBtnText,
      seconds,
      onClickSmsBtn,
      validateSms,
      requestSms,
      setTimer,

      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      couponList,
      landingName,
      startTime,
      endTime,
      backgroundColor,
      buttonBackgroundColor,
      buttonLabel,
      phone,
      sms,
      couponsLink,
      submitDisabled,
      submitText,
      fetchInfo,
      submit,
    }
  },
})
