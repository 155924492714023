<template>
  <div v-if="!notExist" class="coupon-landing">
    <div class="main" :style="{ backgroundColor: backgroundColor }">
      <div class="banner">
        <img :src="landingBanner[0] && landingBanner[0].bannerImgUrl" alt="" />
      </div>

      <coupon v-for="(item, index) in couponList" v-bind="item" :key="index" class="coupon" />

      <div class="form">
        <div class="title">领取信息</div>

        <div class="form-item">
          <input v-model="phone" placeholder="请输入手机号码" />
        </div>

        <div class="form-item">
          <input v-model="sms" placeholder="请输入验证码" />
          <van-button
            class="sms-btn"
            type="primary"
            plain
            :disabled="smsDisabled || overdue"
            @click="onClickSmsBtn"
            >{{ smsBtnText }}</van-button
          >
        </div>

        <div class="form-item">
          <van-button
            class="submit-btn"
            type="primary"
            :color="buttonBackgroundColor"
            :disabled="submitDisabled"
            @click="submit"
            >{{ submitText }}</van-button
          >
        </div>
      </div>
    </div>
    <div class="intro">
      <div class="intro-title">使用说明</div>
      <div class="intro-list">
        <div class="intro-list-item"
          >用领取手机号登录<a class="intro-list-item__link" :href="couponsLink"
            >【讯飞商城】-【我的券码】</a
          >，即可查看与使用</div
        >
        <div v-if="startTime && endTime" class="intro-list-item"
          >活动期限：{{ startTime }} - {{ endTime }}</div
        >
      </div>
    </div>
  </div>
  <div v-else class="empty-wrapper">
    <empty message="当前活动不存在~" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Button } from 'vant'
import useInfo from './model/useInfo'

export default defineComponent({
  name: 'CouponLanding',

  components: {
    [Button.name]: Button,
  },

  setup() {
    const {
      validateSms,
      requestSms,
      smsDisabled,
      smsBtnText,
      seconds,
      onClickSmsBtn,
      setTimer,

      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      couponList,
      landingName,
      startTime,
      endTime,
      backgroundColor,
      buttonBackgroundColor,
      buttonLabel,
      phone,
      sms,
      couponsLink,
      submitDisabled,
      submitText,
      fetchInfo,
      submit,
    } = useInfo()

    return {
      smsDisabled,
      smsBtnText,
      seconds,
      onClickSmsBtn,
      validateSms,
      requestSms,
      setTimer,

      notExist,
      id,
      overdue,
      bannerUrl,
      landingBanner,
      couponList,
      landingName,
      startTime,
      endTime,
      backgroundColor,
      buttonBackgroundColor,
      buttonLabel,
      phone,
      sms,
      couponsLink,
      submitDisabled,
      submitText,
      fetchInfo,
      submit,
    }
  },
})
</script>

<style lang="less" scoped>
.coupon-landing {
  min-height: 100vh;
  background: #f4f6f9;
  .main {
    padding-top: 42px;
    background: #fff;
    .banner {
      width: 690px;
      height: 317px;
      margin: 0 auto 33px;
      img {
        width: 100%;
      }
    }
    .coupon {
      margin: 0 auto 17px;
    }

    .form {
      padding-top: 63px;
      padding-bottom: 53px;
      .title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 58px;
        margin-bottom: 38px;
        padding-top: 13px;
        font-size: 32px;
        font-weight: bold;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #1f1f1f;
        background: url(~@/assets/images/tit_getinformation@3x.png) no-repeat top center;
        background-size: 274px 37px;
        &::after {
          position: absolute;
          display: block;
          content: '';
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 10px;
          background: #427eff;
          border-radius: 6px;
          opacity: 0.6;
        }
      }
      .form-item {
        display: flex;
        align-items: center;
        width: 690px;
        margin: 0 auto;
        margin-bottom: 23px;
        input {
          width: 100%;
          height: 80px;
          border: none;
          background: #f4f6f9;
          color: #999999;
          font-size: 28px;
          padding: 0 24px;
        }
        input::placeholder {
          color: #999999;
          font-size: 28px;
        }
        .sms-btn {
          flex-shrink: 0;
          margin-left: 14px;
          width: 235px;
          height: 80px;
        }
        .submit-btn {
          width: 100%;
          height: 80px;
        }
      }
    }
  }

  .intro {
    &-title {
      margin: 33px 0 28px;
      height: 45px;
      line-height: 45px;
      font-size: 32px;
      font-weight: bold;
      color: #1f1f1f;
      text-align: center;
    }
    &-list {
      width: 690px;
      margin: 0 auto;
      &-item {
        position: relative;
        margin-bottom: 27px;
        padding-left: 24px;
        font-size: 28px;
        line-height: 40px;
        color: #8c8c8c;
        &::before {
          position: absolute;
          top: 14px;
          left: 0;
          display: block;
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #d8d8d8;
        }
        &__link {
          color: @blue;
        }
      }
    }
  }
}

.empty-wrapper {
  height: 100vh;
  padding-top: 126px;
  background: #fff;
}
</style>
