import { ref, computed } from 'vue'

export interface UseSmsOptions {
  validate: () => boolean
  request: () => void
}

export function useSms(options: UseSmsOptions) {
  const initialSeconds = 60
  const initialText = '获取验证码'
  let timer: ReturnType<typeof setInterval>

  const smsDisabled = ref(false)

  const seconds = ref(initialSeconds)

  const smsBtnText = computed(() => {
    return smsDisabled.value ? `剩余${seconds.value}s` : initialText
  })

  const onClickSmsBtn = () => {
    if (options.validate()) {
      options.request()
      setTimer()
    }
  }

  const setTimer = (timeout = 1000) => {
    smsDisabled.value = true
    timer = setInterval(() => {
      if (seconds.value < 2) {
        clearInterval(timer)
        smsDisabled.value = false
        seconds.value = initialSeconds
      } else {
        seconds.value = seconds.value - 1
      }
    }, timeout)
  }

  return {
    smsDisabled,
    smsBtnText,
    seconds,
    onClickSmsBtn,
    setTimer,
  }
}
